export default [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  {
    title: '批次编号',
    dataIndex: 'number',
    sorter: true,
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    sorter: true,
  },
  {
    title: '产品名称',
    dataIndex: 'material_name',
  },
  {
    title: '产品编号',
    dataIndex: 'material_number',
    sorter: true,
  },
  {
    title: '入库数量',
    dataIndex: 'total_quantity',
  },
  {
    title: '剩余数量',
    dataIndex: 'remain_quantity',
  },
  {
    title: '生产日期',
    dataIndex: 'production_date',
    scopedSlots: { customRender: 'production_date' },
    sorter: true,
  },
  {
    title: '过期日期',
    dataIndex: 'expiration_date',
    scopedSlots: { customRender: 'expiration_date' },
    sorter: true,
  },
]